import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <Link to="/files/privacy" target="_blank">
                Privacybeleid
            </Link>
            <Link to="/onze-gegevens">Onze gegevens</Link>
        </footer>
    );
}
