import Neighbourhood from "../../media/neighbourhood.png";
import { FaBeer } from "react-icons/fa";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
//import Marker1 from "../../media/marker1.png";
import Footer from "../components/Footer";

export default function OurInformation() {
    return (
        <>
            <div className="our-information">
                <div className="info">
                    <p>Naam: Zinvolbuurtnieuws.be, AVP Group</p>
                    <p>
                        Adres: Monica Vancloosterstraat 72, 8800 Roeselare,
                        België
                    </p>
                    <p>
                        E-mailadres:{" "}
                        <a href="mailto:info@zinvolbuurtnieuws.be">
                            info@zinvolbuurtnieuws.be
                        </a>
                    </p>
                    <p>Ondernemingsnummer: BE 0723.959.203</p>
                </div>

                <Link to="/" className="main-cta button-1">
                    Terug naar startpagina
                </Link>
            </div>
            <Footer />
        </>
    );
}
