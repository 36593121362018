import Neighbourhood from "../../media/neighbourhood.png";
import { FaBeer } from "react-icons/fa";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
//import Marker1 from "../../media/marker1.png";

export default function Home() {
    return (
        <div className="home">
            <div className="screen-container-1">
                <header className="header-1">
                    <h1>
                        <span className="marker">Zinvol</span> Buurtnieuws
                    </h1>
                    <p>Zeg vaarwel tegen nutteloze reclame.</p>
                </header>
                <div className="cta-row">
                    <FaLongArrowAltRight className="arrow" />
                    <Link to="/inschrijven" className="main-cta button-1">
                        NU INSCHRIJVEN
                    </Link>
                    <FaLongArrowAltLeft className="arrow" />
                </div>
                <p className="ps">
                    PS: Voor elke e-mail die JIJ leest, steunen WIJ een goed
                    doel in jouw buurt.
                    <br />
                    Het kost je niets en tòch kan je helpen!
                </p>
            </div>
            <div className="details">
                <img
                    className="neighbourhood"
                    src={Neighbourhood}
                    alt="neighbourhood drawing"
                />
                <div className="screen-container-1">
                    <h2>Wat doen we concreet?</h2>
                    <p className="bold">
                        Elke maand bundelen we ZINVOLLE mededelingen uit uw
                        buurt in een korte e-mail.
                    </p>
                    <p>
                        We delen oprecht interessante initiatieven van lokale
                        bedrijven, organisaties en nieuwe ondernemers uit uw
                        buurt. Heb je zèlf iets interessant te melden? Dan kan
                        dat ook!
                    </p>
                    <p className="italic">Bijvoorbeeld...</p>
                    <ul className="example-list">
                        <li>
                            <ul>
                                <li>
                                    <h3>Ontdek...</h3>
                                    <ul>
                                        <li>workshops</li>
                                        <li>hobbyclubs</li>
                                        <li>proeverijen</li>
                                        <li>(theater-) voorstellingen</li>
                                        <li>
                                            amusante activiteiten voor koppels
                                            en vrienden
                                        </li>
                                        <li>feestjes</li>
                                        <li>computerlessen</li>
                                        <li>groepsaankopen</li>
                                        <li>buurtbijeenkomsten</li>
                                        <li>popup-plekjes</li>
                                        <li>sportclubs</li>
                                        <li>openingen</li>
                                        <li>(flexi-) jobs</li>
                                        <li>winkelacties</li>
                                        <li>kortingen</li>
                                        <li>...</li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Vind een...</h3>
                                    <ul>
                                        <li>babysit</li>
                                        <li>klusjesman</li>
                                        <li>huishoudhulp</li>
                                        <li>verpleegster</li>
                                        <li>boodschapper</li>
                                        <li>tuinman</li>
                                        <li>...</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <ul>
                                <li>
                                    <h3>Krijg korting op...</h3>
                                    <ul>
                                        <li>restaurant</li>
                                        <li>bioscooptickets</li>
                                        <li>wellness & spa</li>
                                        <li>verrassingspakketten</li>
                                        <li>...</li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Luister naar...</h3>
                                    <ul>
                                        <li>(nieuwe) ondernemers</li>
                                        <li>vrijwilligers</li>
                                        <li>studentenprojecten</li>
                                        <li>...</li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Overige...</h3>
                                    <ul>
                                        <li>rommelmarkten</li>
                                        <li>wedstrijden</li>
                                        <li>geschenktips</li>
                                        <li>buurtnieuws</li>
                                        <li>verloren gelopen huisdieren</li>
                                        <li>uitverkopen</li>
                                        <li>garageverkopen</li>
                                        <li>huisdierenopvang</li>
                                        <li>evenementen</li>
                                        <li>...</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
}
