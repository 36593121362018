import { Link } from "react-router-dom";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useReducer, useState } from "react";
import { isValidEmail } from "../extra/validations";
import axios from "axios";
import Spinner from "../components/Spinner";
import { map } from "lodash";

function reducer(state, action) {
    switch (action.type) {
        case "setEmail":
            state.email = action.payload.value;
            return state;
        case "setNeighbourhood":
            state.neighbourhood = action.payload.value;
            return state;
        case "setConsent":
            state.consent = action.payload.value;
            return state;
        case "setUniqueCode":
            state.uniqueCode = action.payload.value;
            return state;
        case "setBirthDate":
            state.birthDate = action.payload.value;
            return state;
        case "setSex":
            state.sex = action.payload.value;
            return state;
        case "setStreet":
            state.street = action.payload.value;
            return state;
        case "setDurationOfLivingInNeighbourhood":
            state.durationOfLivingInNeighbourhood = action.payload.value;
            return state;
        case "setAmountOfChildren":
            state.amountOfChildren = action.payload.value;
            return state;
        case "setAmountOfChildrenStillLivingAtHome":
            state.amountOfChildrenStillLivingAtHome = action.payload.value;
            return state;
        case "setAmountOfGrandChildren":
            state.amountOfGrandChildren = action.payload.value;
            return state;
        default:
            break;
    }
}

export default function Home() {
    const [state, dispatch] = useReducer(reducer, {
        email: "",
        neighbourhood: "undefined",
        consent: false,
        uniqueCode: null,
        birthDate: "",
        sex: "undefined",
        street: "",
        durationOfLivingInNeighbourhood: "undefined",
        amountOfChildren: "undefined",
        amountOfChildrenStillLivingAtHome: "undefined",
        amountOfGrandChildren: "undefined",
    });
    const [currentStep, setCurrentStep] = useState(1);
    const [showSpinner, setShowSpinner] = useState(false);

    const totalAmountOfExtraQuestions = 7;

    function goToNextStep() {
        setCurrentStep(currentStep + 1);
    }
    function goToPreviousStep() {
        setCurrentStep(currentStep - 1);
    }

    function quickPatch(propertyName, value) {
        setShowSpinner(true);
        axios
            .patch(`apiv1/subscribers`, {
                uniqueCode: state.uniqueCode,
                property: propertyName,
                value: value,
            })
            .then((res) => {
                setShowSpinner(false);
                if (propertyName == "amountOfChildren" && value == "geen") {
                    setCurrentStep(currentStep + 3);
                } else {
                    goToNextStep();
                }
            })
            .catch((err) => {
                setShowSpinner(false);
                alert(
                    "Er liep iets fout tijdens het versturen. Controleer de gegevens en probeer opnieuw. Indien het probleem blijft aanhouden, gelieve ons te contacteren via info@zinvolbuurtnieuws.be"
                );
            });
    }

    function validateCurrentStep() {
        switch (currentStep) {
            case 1:
                if (!isValidEmail(state.email)) {
                    alert(
                        "Controleer a.u.b. of u een correct e-mailadres heeft ingevoerd."
                    );
                    break;
                }
                if (state.neighbourhood == "undefined") {
                    alert("Kies a.u.b. een buurt.");
                    break;
                }
                goToNextStep();
                break;
            case 2:
                if (state.consent === false) {
                    alert(
                        "Om in te schrijven dient u eerst toestemming te geven door een vinkje te plaatsen."
                    );
                    break;
                }

                setShowSpinner(true);
                axios
                    .post(`apiv1/subscribers`, state)
                    .then((res) => {
                        dispatch({
                            type: "setUniqueCode",
                            payload: { value: res },
                        });
                        setShowSpinner(false);
                        goToNextStep();
                    })
                    .catch((err) => {
                        setShowSpinner(false);
                        alert(
                            "Er liep iets fout tijdens het inschrijven. Controleer de gegevens en probeer opnieuw. Indien het probleem blijft aanhouden, gelieve ons te contacteren via info@zinvolbuurtnieuws.be"
                        );
                    });

                break;
            case 3:
                if (state.birthDate == "") {
                    alert("Vul a.u.b. uw geboortedatum in.");
                    break;
                }
                quickPatch("birthDate", state.birthDate);
                break;
            case 4:
                if (state.street == "") {
                    alert("Vul a.u.b. de naam van uw straat in.");
                    break;
                }
                quickPatch("street", state.street);
                break;
            case 5:
                if (state.sex == "undefined") {
                    alert("Gelieve een geslacht aan te duiden.");
                    break;
                }
                quickPatch("sex", state.sex);
                break;
            case 6:
                if (state.durationOfLivingInNeighbourhood == "undefined") {
                    alert(
                        `Duid a.u.b. aan hoelang u al in ${state.neighbourhood} woont.`
                    );
                    break;
                }
                quickPatch(
                    "durationOfLivingInNeighbourhood",
                    state.durationOfLivingInNeighbourhood
                );
                break;
            case 7:
                if (state.amountOfChildren == "undefined") {
                    alert(`Duid a.u.b. aan hoeveel kinderen u heeft.`);
                    break;
                }
                quickPatch("amountOfChildren", state.amountOfChildren);
                break;
            case 8:
                if (state.amountOfChildrenStillLivingAtHome == "undefined") {
                    alert(
                        `Duid a.u.b. aan hoeveel kinderen er nog bij u thuis wonen.`
                    );
                    break;
                }
                quickPatch(
                    "amountOfChildrenStillLivingAtHome",
                    state.amountOfChildrenStillLivingAtHome
                );
                break;
            case 9:
                if (state.amountOfGrandChildren == "undefined") {
                    alert(`Duid a.u.b. aan hoeveel kleinkinderen u heeft.`);
                    break;
                }
                quickPatch(
                    "amountOfGrandChildren",
                    state.amountOfGrandChildren
                );
                break;

            default:
                break;
        }
    }

    const StandardButtons = () => (
        <div className="buttons">
            <button onClick={goToPreviousStep} className="back">
                <HiChevronLeft className="icon" /> Vorige
            </button>
            <button onClick={validateCurrentStep} className="continue button-1">
                Volgende
                <HiChevronRight className="icon" />
            </button>
        </div>
    );

    return (
        <div className="subscribe">
            <div className="screen-container-1">
                <header className="header-1">
                    <h1>
                        <span className="marker">Zinvol</span> Buurtnieuws
                    </h1>
                </header>
                {currentStep == 1 && (
                    <div className="form-container">
                        <div className="input-group">
                            <label>Wat is uw e-mailadres?</label>
                            <input
                                type="email"
                                placeholder="Vul hier uw e-mailadres in"
                                defaultValue={state.email}
                                onChange={(e) =>
                                    dispatch({
                                        type: "setEmail",
                                        payload: { value: e.target.value },
                                    })
                                }
                            />
                        </div>

                        <div className="input-group">
                            <label>In welke buurt woont u?</label>
                            <select
                                defaultValue={state.neighbourhood}
                                onChange={(e) =>
                                    dispatch({
                                        type: "setNeighbourhood",
                                        payload: { value: e.target.value },
                                    })
                                }
                            >
                                <option value="undefined" disabled>
                                    Kies hier uw buurt
                                </option>
                                <optgroup label="Roeselare">
                                    <option value="Roeselare">Roeselare</option>
                                    <option value="Rumbeke">Rumbeke</option>
                                    <option value="Beveren">Beveren</option>
                                    <option value="Oekene">Oekene</option>
                                </optgroup>
                                <optgroup label="Hooglede">
                                    <option value="Hooglede">Hooglede</option>
                                    <option value="Gits">Gits</option>
                                    <option value="Sint-Jozef">
                                        Sint-Jozef
                                    </option>
                                    <option value="Sleihage">Sleihage</option>
                                    <option value="Onledemolen">
                                        Onledemolen
                                    </option>
                                </optgroup>
                                <optgroup label="Staden">
                                    <option value="Staden">Staden</option>
                                    <option value="Oostnieuwkerke">
                                        Oostnieuwkerke
                                    </option>
                                    <option value="Westrozebeke">
                                        Westrozebeke
                                    </option>
                                </optgroup>
                                <optgroup label="Overige">
                                    <option value="Ardooie">Ardooie</option>
                                </optgroup>
                            </select>
                        </div>

                        <div className="buttons">
                            <Link to="/" className="back">
                                <HiChevronLeft className="icon" /> Vorige
                            </Link>
                            <button
                                className="continue button-1"
                                onClick={validateCurrentStep}
                            >
                                Volgende
                                <HiChevronRight className="icon" />
                            </button>
                        </div>
                    </div>
                )}
                {showSpinner ? (
                    <Spinner />
                ) : (
                    <>
                        {currentStep == 2 && (
                            <>
                                <p className="important">
                                    Wij zullen uw gegevens nooit met anderen
                                    delen!
                                </p>
                                <div className="form-container medium fc-consent">
                                    <div className="input-group checkbox-wide">
                                        <input
                                            id="cb-consent-1"
                                            type="checkbox"
                                            defaultChecked={state.consent}
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "setConsent",
                                                    payload: {
                                                        value: e.target.checked,
                                                    },
                                                })
                                            }
                                        />
                                        <label htmlFor="cb-consent-1">
                                            Ik geef toestemming om e-mails te
                                            ontvangen en ga akkoord met het{" "}
                                            <a
                                                href="/files/privacy"
                                                target="_blank"
                                            >
                                                privacybeleid
                                            </a>{" "}
                                            van 'Zinvol Buurtnieuws'.
                                        </label>
                                    </div>
                                    <div className="buttons">
                                        <button
                                            onClick={goToPreviousStep}
                                            className="back"
                                        >
                                            <HiChevronLeft className="icon" />{" "}
                                            Vorige
                                        </button>
                                        <button
                                            onClick={validateCurrentStep}
                                            className="continue button-1"
                                        >
                                            Nu inschrijven
                                            <HiChevronRight className="icon" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {currentStep == 3 && (
                            <>
                                <div className="form-container medium">
                                    <h2>Super!</h2>
                                    <p className="mb-2">
                                        We hebben uw e-mailadres en buurt veilig
                                        bewaard!
                                    </p>
                                    <h2>Maar...</h2>
                                    <p className="mb-4">
                                        Om u betere mededelingen te bezorgen
                                        zouden we u eigenlijk nèt iets beter
                                        moeten kennen.
                                    </p>
                                    <div className="input-group">
                                        <label>
                                            Op welke dag bent u geboren?
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue={state.birthDate}
                                            placeholder="Bijvoorbeeld: 23/11/1992"
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "setBirthDate",
                                                    payload: {
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="buttons only-one-button">
                                        <button
                                            onClick={validateCurrentStep}
                                            className="continue button-1"
                                        >
                                            Volgende
                                            <HiChevronRight className="icon" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {[4, 5, 6, 7, 8, 9].includes(currentStep) && (
                            <p>Vraag {currentStep - 3} van 6</p>
                        )}
                        {currentStep == 4 && (
                            <>
                                <div className="form-container medium">
                                    <div className="input-group">
                                        <label>In welke straat woont u?</label>
                                        <label className="details">
                                            (Uw huisnummer hebben we niet
                                            nodig.)
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue={state.street}
                                            placeholder="Vul hier uw straatnaam in"
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "setStreet",
                                                    payload: {
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <StandardButtons />
                                </div>
                            </>
                        )}
                        {currentStep == 5 && (
                            <>
                                <div className="form-container medium">
                                    <div className="input-group">
                                        <label>Wat is uw geslacht?</label>
                                        <select
                                            defaultValue={state.sex}
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "setSex",
                                                    payload: {
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        >
                                            <option value="undefined" disabled>
                                                Kies hier uw geslacht
                                            </option>
                                            <option value="man">Man</option>
                                            <option value="vrouw">Vrouw</option>
                                            <option value="anders">
                                                Anders
                                            </option>
                                        </select>
                                    </div>
                                    <StandardButtons />
                                </div>
                            </>
                        )}

                        {currentStep == 6 && (
                            <>
                                <div className="form-container medium">
                                    <div className="input-group">
                                        <label>
                                            Hoelang woont u al in{" "}
                                            {state.neighbourhood}?
                                        </label>
                                        <select
                                            defaultValue={
                                                state.durationOfLivingInNeighbourhood
                                            }
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "setDurationOfLivingInNeighbourhood",
                                                    payload: {
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        >
                                            <option value="undefined" disabled>
                                                Kies hier hoelang
                                            </option>
                                            <option value="1-">
                                                minder dan 1 jaar
                                            </option>
                                            <option value="1-2">
                                                1 - 2 jaar
                                            </option>
                                            <option value="2-5">
                                                2 - 5 jaar
                                            </option>
                                            <option value="5-10">
                                                5 - 10 jaar
                                            </option>
                                            <option value="10-20">
                                                10 - 20 jaar
                                            </option>
                                            <option value="20+">
                                                meer dan 20 jaar
                                            </option>
                                            <option value="my_whole_life">
                                                (bijna) heel mijn leven
                                            </option>
                                        </select>
                                    </div>
                                    <StandardButtons />
                                </div>
                            </>
                        )}
                        {currentStep == 7 && (
                            <>
                                <div className="form-container medium">
                                    <div className="input-group">
                                        <label>Hoeveel kinderen heeft u?</label>
                                        <select
                                            defaultValue={
                                                state.amountOfChildren
                                            }
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "setAmountOfChildren",
                                                    payload: {
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        >
                                            <option value="undefined" disabled>
                                                Kies hier hoeveel kinderen u
                                                heeft
                                            </option>

                                            {[
                                                "geen",
                                                1,
                                                2,
                                                3,
                                                4,
                                                5,
                                                6,
                                                7,
                                                8,
                                                9,
                                                10,
                                                11,
                                                12,
                                                13,
                                                14,
                                                15,
                                            ].map((choice) => (
                                                <option
                                                    value={choice}
                                                    key={
                                                        "amountOfChildren" +
                                                        choice
                                                    }
                                                >
                                                    {choice}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <StandardButtons />
                                </div>
                            </>
                        )}
                        {currentStep == 8 && (
                            <>
                                <div className="form-container medium">
                                    <div className="input-group">
                                        {state.amountOfChildren == 1 ? (
                                            <label>
                                                Woont uw kind nog thuis?
                                            </label>
                                        ) : (
                                            <label>
                                                Hoeveel van uw{" "}
                                                {state.amountOfChildren}{" "}
                                                kinderen wonen momenteel nog
                                                thuis?
                                            </label>
                                        )}
                                        <select
                                            defaultValue={
                                                state.amountOfChildrenStillLivingAtHome
                                            }
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "setAmountOfChildrenStillLivingAtHome",
                                                    payload: {
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        >
                                            <option value="undefined" disabled>
                                                {state.amountOfChildren == 1
                                                    ? "Maak een keuze"
                                                    : "Kies hier een aantal"}
                                            </option>

                                            {state.amountOfChildren == 1 ? (
                                                <>
                                                    <option value="0">
                                                        Nee
                                                    </option>
                                                    <option value="1">
                                                        Ja
                                                    </option>
                                                </>
                                            ) : (
                                                Array.from(
                                                    Array(
                                                        parseInt(
                                                            state.amountOfChildren
                                                        ) + 1
                                                    ).keys()
                                                ).map((choice) => (
                                                    <option
                                                        value={choice}
                                                        key={
                                                            "amountofchildren-" +
                                                            choice
                                                        }
                                                    >
                                                        {choice}
                                                    </option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                    <StandardButtons />
                                </div>
                            </>
                        )}
                        {currentStep == 9 && (
                            <>
                                <div className="form-container medium">
                                    <div className="input-group">
                                        <label>
                                            Hoeveel kleinkinderen heeft u?
                                        </label>
                                        <select
                                            defaultValue={
                                                state.amountOfGrandChildren
                                            }
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "setAmountOfGrandChildren",
                                                    payload: {
                                                        value: e.target.value,
                                                    },
                                                })
                                            }
                                        >
                                            <option value="undefined" disabled>
                                                Kies hier een aantal
                                            </option>

                                            {[
                                                "geen",
                                                1,
                                                2,
                                                3,
                                                4,
                                                5,
                                                6,
                                                7,
                                                8,
                                                9,
                                                "meer",
                                            ].map((choice) => (
                                                <option
                                                    key={
                                                        "grandchildren-" +
                                                        choice
                                                    }
                                                    value={
                                                        choice == "meer"
                                                            ? "9+"
                                                            : choice
                                                    }
                                                >
                                                    {choice}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <StandardButtons />
                                </div>
                            </>
                        )}
                        {currentStep == 10 && (
                            <div className="form-container medium">
                                <h2>U bent helemaal klaar!</h2>
                                <p className="mb-4">
                                    Bedankt voor uw inschrijving.
                                </p>
                                <div className="buttons only-one-button">
                                    <Link to="/" className="continue button-1">
                                        <HiChevronLeft className="icon" />
                                        Terug naar de startpagina
                                    </Link>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
