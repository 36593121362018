require("./bootstrap");

// React Components

import { createRoot } from "react-dom/client";
//import ReactDOM from "react-dom/client";
import Home from "./routes/Home";
import Subscribe from "./routes/Subscribe";
import ErrorPage from "./error-page";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import OurInformation from "./routes/OurInformation";

const container = document.getElementById("react-app");
const root = createRoot(container);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/inschrijven",
        element: <Subscribe />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/onze-gegevens",
        element: <OurInformation />,
        errorElement: <ErrorPage />,
    },
]);

root.render(<RouterProvider router={router} />);
/*
ReactDOM.createRoot(document.getElementById("react-app")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
*/
